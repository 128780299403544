<template>
    <v-dialog v-model="deleteSnapshotDialog" max-width="700">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" :disabled="disabled" icon text color="secondary" e2e-snapshot-delete><v-icon>delete</v-icon></v-btn>
                </template>
                <span>Delete snapshot</span>
            </v-tooltip>
        </template>
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center w-100">
                    <v-icon>delete</v-icon>
                    <span class="text-uppercase secondary--text ml-1">delete snapshot</span>
                    <v-spacer></v-spacer>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <div class="mt-3">
                    <span class="font-weight-bold">Snapshot Name</span>
                    : {{ snapshotData.long_id }}
                </div>
                <div class="mt-3">
                    <span class="font-weight-bold">Snapshot Timestamp</span>
                    :
                    <span v-if="snapshotData.snapshot_timestamp">{{ snapshotData.snapshot_timestamp | dateTimeToHuman }}</span>
                </div>
                <v-alert outlined color="error" prominent type="warning" class="mt-5">
                    <span class="font-weight-bold">Here is what happens when you delete a snapshot</span>
                    <ul class="mt-3">
                        <li>
                            <span class="subtitle-2">The contents (files, tables, and application) of the snapshot will be deleted permanently.</span>
                        </li>
                    </ul>
                    <v-divider class="my-4 secondary" style="opacity: 0.22"></v-divider>
                    <v-checkbox class="secondary--text" color="error" v-model="agreeBox" label="I understand" e2e-snapshot-delete-confirm></v-checkbox>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <div class="d-flex flex-column w-100">
                    <div class="d-flex align-center justify-end">
                        <v-btn color="secondary" text @click="deleteSnapshotDialog = false">close</v-btn>
                        <DeleteButton
                            buttonName="delete"
                            :objectType="nuvolosObjectTypes.SNAPSHOT"
                            :apiURL="'/snapshots/' + $props.snapshotData.snid"
                            :id="parseInt($props.snapshotData.snid, 10)"
                            getterString="snapshotStore/snapshotLongNameById"
                            :higherLevelId="parseInt($route.params.iid, 10)"
                            :routeAfter="
                                $props.snapshotData.snid.toString() === $route.params.snid.toString()
                                    ? {
                                          name: 'snapshot-overview',
                                          params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: developmentSnapshot.snid }
                                      }
                                    : null
                            "
                            fetchString="instanceStore/fetchInstanceSnapshots"
                            @error="error = $event.error"
                            :disabled="!agreeBox"
                            :isInsideDialog="true"
                            :objectName="$props.snapshotData.long_id"
                            :isAsyncDeletion="true"></DeleteButton>
                    </div>
                    <div>
                        <v-alert :value="error" color="error" prominent type="error" class="mt-5" text>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">Snapshot Deletion Error</span>
                                An error has occurred and the snapshot could not be deleted. Please try again later and if the problem persists contact
                                support@alphacruncher.com
                            </div>
                        </v-alert>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
const DeleteButton = () => import('@/components/DeleteButton.vue')

export default {
    name: 'SnapshotDeleteDialog',
    mixins: [enumsData],
    components: { DeleteButton },
    props: {
        snapshotData: Object,
        disabled: Boolean
    },
    data() {
        return {
            agreeBox: false,
            deleteSnapshotDialog: false,
            error: false
        }
    },
    computed: {
        ...mapGetters('snapshotStore', ['developmentSnapshot'])
    },
    watch: {
        deleteSnapshotDialog: function (nextVal) {
            if (!nextVal && this.error) {
                this.error = false
            }
        }
    }
}
</script>
